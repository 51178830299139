.App {
  background: black;
}

.credit_tiles {
    overflow-y: scroll;
    height: auto;
    flex: 0.8;
    color: white;
    padding: 30px;
    background: black;
}

.head {
    color: white;
    padding: 30px;
    background: black;
    
}

.head > h2 {
  display: flex;
  justify-content: flex-start;
}

.head > h4 {
  display: flex;
  justify-content: flex-start;
}

.head > h6 {
  display: flex;
  justify-content: flex-end;
}

.insta {
  display: flex;
  justify-content: flex-end;
  background-color: black;
  color: white;
  margin-bottom: 6px;
}

.fb {
  display: flex;
  justify-content: flex-end;
  background-color: black;
  color: white;
  margin-bottom: 6px;
}

.credits_title  {
    color: white;
    display: flex;
    justify-content: center;
    padding: 14px;
    background: black;
    margin-left: 46px;
}

.credit_tiles::-webkit-scrollbar {
  display: none;
}

.all_cards {
  background-color: black;
  padding: 0px;
}

.spoti_card {

  background-color: black;
}

.bandcamp_tile {
  background-color: black;
  
}


/* @media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
} */

/* .App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
} */

/* .App-link {
  color: #61dafb;
} */

/* @keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
} */
